@font-face { 
    font-family: "MullerRegular";
    src: url("../../fonts/MullerRegular.eot?") format("eot"), /* IE8+, знак ? позволяет обойти баг в обработчике значения src */
         url("../../fonts/MullerRegular.woff") format("woff"), /* все современные браузеры, IE9+ */
         url("../../fonts/MullerRegular.ttf") format("truetype"); /* все современные браузеры */
}

@font-face { 
    font-family: "MullerBold";
    src: url("../../fonts/MullerBold.eot?") format("eot"), /* IE8+, знак ? позволяет обойти баг в обработчике значения src */
        url("../../fonts/MullerBold.woff") format("woff"), /* все современные браузеры, IE9+ */
        url("../../fonts/MullerBold.ttf") format("truetype"); /* все современные браузеры */
}