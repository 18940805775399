@media (max-width: 1200px) {
	.top-sidebar {
		& .row {
			display: inherit; } }

	.phone-block {
		padding: 5px 0;
		margin: 0;
		margin-top: 30px;
		display: inline-block;
		&:first-child {
			margin: 0;
			margin-right: 35px; }
		a {
			font-size: 19px; } }

	#search {
		padding: 16px 0; }

	.cart {
		justify-content: flex-end;
		margin-top: 30px; }

	.categories-menu {
		&__link {
			font-size: 12px; }
		&__dropdown-list {
			top: 47px; } }

	.popular {
		margin-top: 40px;
		&__heading {
			margin-bottom: 30px; } }

	.ct-block-1 {
		.ct-block__image {
			right: 0%; } }
	.ct-block-2 {
		overflow-x: hidden;
		.ct-block__image {
			right: -10%; } }
	.ct-block-3 {
		.ct-block__image {
			right: 0%; } }
	.ct-block-4 {
		overflow-x: hidden;
		.ct-block__image {
			right: -30%; } }
	.ct-block-5 {
		overflow-x: hidden;
		.ct-block__image {
			right: -15%; } }
	.ct-block-6 {
		overflow-x: hidden;
		.ct-block__image {
			right: -15%; } }
	.ct-block-7 {
		overflow-x: hidden;
		.ct-block__image {
			right: -25%; } }

	.phone-block {
		a {
			font-size: 15px; } }

	.footer-phone-block {
		margin: 0; }

	.category-bar {
		display: block; }

	.category-heading {
		font-size: 14px;
		padding: 18px;
		color: $black;
		margin: 0;
		background-color: transparent;
		letter-spacing: 1.96px;
		i {
			color: $black; } }

	.category-block {
		margin-bottom: 20px;
		display: none; }

	.category-banner {
		display: none; }

	.account-block {
		display: block;
		a {
			font-family: $regular; } }

	.category {
		display: none; }

	.breadcrumb {
		margin: 0; }

	.product {
		margin-bottom: 30px; }

	.category-block {
		margin-top: 0; } }

@media (max-width: 991px) {
	.top-nav {
		display: none; }

	.logo {
		height: 50px;
		img {
			height: 100%; }
		a {
			height: 100%;
			display: block;
			text-align: right; } }

	.phone-block {
		margin: 0; }

	.phone-block-wrapper {
		margin-top: 20px;
		margin-bottom: 20px; }

	#search {
		padding: 0;
		input {
			border-radius: 0;
			padding: 26px; }
		button {
			top: 6px; }
		span {
			width: 0%; } }

	.cart {
		background-color: $accent;
		color: #ffffff;
		padding: 15px 0;
		display: block;
		margin: 0;
		i {
			display: none; }
		&__info {
			display: flex;
			align-items: center;
			justify-content: center; }
		&__heading {
			margin: 0;
			margin-right: 20px;
			color: #ffffff; }
		span {
			color: #ffffff; } }

	.categories-menu {
		display: none; }

	.top-sidebar {
		padding: 0;
		padding-top: 30px; }

	.carousel {
		display: none; }

	.popular {
		margin-top: 20px; }

	.ct-block {
		&__image {
			display: none; }
		&__item {
			margin-bottom: 15px; } }

	.about {
		margin-top: 0;
		img {
			display: none; } }

	.footer {
		margin-top: 40px;
		& .row-line-2 {
			display: block; }
		&__button {
			margin: 0; } }

	.footer-nav {
		&__list {
			display: block; }
		&__item {
			margin-bottom: 10px; } }

	.footer-account {
		text-align: left; }

	.email {
		margin: 20px 0; }

	#content {
		.thumbnail {
			height: auto; }

		.product-card-heading {
			text-align: center; }

		.product-options {
			text-align: center; }

		.price-t {
			text-align: center;
			padding: 20px 0 30px 0; }

		.amount {
			justify-content: center;
			&__label {
				left: 50%;
				margin-left: -22px; } } }

	.product-btn-block {
		text-align: center; } }

@media (max-width: 768px) {
	.product__heading {
		height: auto; }

	.product__text {
		height: auto; }

	.col-accent {
		background: $accent; }

	#search input {
		box-shadow: none; }

	#search .form-control:focus {
		box-shadow: none; } }


@media (max-width: 575px) {
	h2 {
		font-size: 20px; }

	.breadcrumb {
		padding: 20px 0; }

	.thumbnail {
		img {
			height: 250px; } }

	.product-btn-block {
		display: flex;
		justify-content: center;
		button {
			font-size: 13px; } }

	.remodal-menu {
		padding: 0; }

	.remodal {
		vertical-align: top;
		padding: 0;
		padding-top: 30px; }

	.phone-block {
		&:first-child {
			margin: 0; }
		span {
			padding-left: 0; }
		i {
			display: none; }
		a {
			font-size: 13px; } }

	.phone-block-wrapper {
		margin-bottom: 10px;
		margin-top: 30px; }

	.heading, #content h1 {
		font-size: 25px; }

	.ct-block {
		&__item {
			padding: 15px; } }

	.about {
		&__text {
			margin-bottom: 20px; } }

	.footer-info {
		display: block;
		&__list {
			display: block; }
		&__link {
			margin-left: 0; } }

	.footer {
		padding: 40px 0;
		& .phone-block a {
			font-size: 16px; } }

	.product-card-heading {
		font-size: 20px !important; }

	#content .price-t {
		font-size: 25px; }

	.product-search .form-control {
		width: 100%; }

	.cat-search {
		justify-content: center;
		li {
			width: 110px;
			margin-right: 25px; } }

	.sanotint-colors {
		width: 100% !important; } }
