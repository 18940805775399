@import "parts/bootstrap.css";
@import "parts/bootstrap-theme.css";
@import "parts/mail.css";
@import "parts/remodal.css";
@import "parts/remodal-default-theme.css";
@import "parts/font.css";
// Font Face

$regular: "MullerRegular";
$bold: "MullerBold";

$accent: #099ce3;
$gray-line: #e8e8e8;
$black: #272727;

body {
    font-family: $regular, sans-serif;
    background-color: #faf8f6; }

.d-flex {
    display: flex; }

.d-none {
    display: none; }

.justify-content-center {
    justify-content: center; }

a {
    color: #24c77c; }

a:hover {
    text-decoration: none;
    color: #1dd883; }

a:active, a:focus, a:hover {
    outline: none;
    text-decoration: none; }

a:focus {
    outline-offset: none; }

a:focus {
    outline: none; }

.top-nav {
    background-color: #ffffff;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray-line;
    &__list {
        display: flex;
        padding: 0;
        margin: 0;
        list-style: none; }
    &__link {
        margin-right: 20px;
        font-size: 13px;
        letter-spacing: 0.28px;
        color: #000000;
        transition: 0.2s;
        &:hover {
            color: $accent; } } }

.account {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    &__link {
        margin-left: 23px;
        font-size: 13px;
        letter-spacing: 0.28px;
        color: #000000;
        transition: 0.2s;
        &:hover {
            color: $accent; }
        i {
            margin-right: 10px; } } }

.top-sidebar {
    background-color: #ffffff;
    padding: 30px 0;
    .row {
        display: flex;
        align-items: center; } }

.logo {
    img {
        max-width: 100%;
        height: auto; } }

#search {
    width: 100%;
    input {
        position: relative;
        width: 100%;
        font-size: 15px;
        color: #585858;
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
        border-radius: 40px;
        background-color: #ffffff;
        padding: 14px 25px 10px 25px;
        border: none;
        transition: 0.5s; }
    .form-control:focus {
        border-color: transparent;
        box-shadow: 0 3px 15px rgba(9,156,227,.4); }
    button {
        position: absolute;
        top: 4px;
        right: 8px;
        background: none;
        border: none;
        box-shadow: none;
        i {
            color: #585858; } } }

.phone-block {
    margin-left: 25px;
    &:first-child {
        margin-bottom: 15px; }
    a {
        display: block;
        font-size: 17px;
        color: #000000;
        letter-spacing: 1.7px;
        &:hover {
            color: #000000; } }
    i {
        margin-right: 10px;
        font-size: 15px; }
    span {
        display: block;
        color: #888888;
        letter-spacing: 0.26px;
        font-size: 13px;
        padding-left: 30px; } }

.phone-block-wrapper {
    display: flex;
    justify-content: space-between; }

.cart {
    display: flex;
    align-items: center;
    &:hover i {
        box-shadow: 0 0 20px rgba(9, 156, 227, 1); }
    i {
        line-height: 2.8;
        font-size: 16px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        box-shadow: 0 0 15px rgba(9, 156, 227, 0.61);
        background-color: $accent;
        text-align: center;
        color: #ffffff;
        margin-right: 20px;
        transition: 0.5s; }
    &__heading {
        font-size: 14px;
        color: #000000;
        text-transform: uppercase;
        letter-spacing: 1.96px;
        font-family: $bold;
        margin: 0;
        margin-bottom: 5px; }
    span {
        font-size: 15px;
        color: #000000;
        letter-spacing: 1.5px; } }

.categories-menu {
    background-color: $accent;
    &__dropdown-list {
        z-index: 10;
        display: none;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 45px;
        left: 0;
        width: 140%;
        list-style: none;
        background-color: #24c77c; }
    &__dropdown-link {
        display: block;
        padding: 10px 10px 10px 25px;
        color: #ffffff;
        transition: 0.1s;
        &:hover {
            background-color: #23b672;
            color: #ffffff; }
        &:focus {
            color: #ffffff; } }
    &__list {
        padding: 0;
        margin: 0;
        list-style: none;
        height: 100%;
        display: flex;
        justify-content: space-between; }
    &__item {
        position: relative;
        transition: 0.1s;
        &:hover ul {
            display: block; }
        &:hover {
            background-color: #24c77c;
            color: #ffffff; } }
    &__link {
        display: block;
        padding: 12px 10px;
        color: #ffffff;
        font-size: 15px;
        height: 100%;
        transition: 0.1s;
        &:hover, &:focus {
            color: #ffffff; } } }

.carousel {
    &__slide {
        text-align: center;
        display: flex !important;
        justify-content: center;
        img {
            max-width: 100%;
            height: auto; } } }
.slick-dots {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    margin-top: 25px;
    & button {
        display: none; }
    & li {
        width: 27px;
        height: 8px;
        border-radius: 28px;
        background-color: $accent;
        margin-right: 10px;
        cursor: pointer;
        opacity: 0.3; }
    & .slick-active {
        opacity: 1; } }

.about-store {
    margin-top: 30px;
    font-size: 15px;
    color: $black;
    line-height: 24px; }

.heading {
    color: $black;
    font-size: 35px;
    font-family: $bold;
    letter-spacing: -0.4px; }

.popular {
    position: relative;
    margin-top: 20px;
    &__heading {
        margin-bottom: 20px; } }

.product {
    margin-bottom: 15px;
    padding: 20px;
    border: 1px solid #dcdcdc;
    &__thumb {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center; }
    &__heading {
        font-size: 17px;
        color: $black;
        height: 45px;
        font-family: $bold;
        line-height: 22px;
        margin: 0;
        margin-bottom: 4px; }
    &__text {
        margin: 0 !important;
        font-size: 13px !important;
        line-height: 1.4 !important;
        height: 54px;
        overflow-y: hidden; }
    &__price-block {
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between; }
    &__price {
        display: block;
        color: $black;
        font-size: 20px;
        font-family: $bold; } }

.popular-product {
    margin: 10px; }

.btn {
    text-transform: uppercase;
    font-size: 13px;
    font-family: $bold;
    color: #ffffff;
    border-radius: 40px;
    border: none;
    padding: 10px 25px;
    transition: 0.1s;
    &-default {
        background: transparent;
        border: 1px solid $accent;
        color: $black;
        box-shadow: 0 0 10px rgba(0, 149, 222, 0.6);
        text-shadow: none;
        &:hover {
            background-color: $accent;
            color: #ffffff;
            border: 1px solid $accent; } }
    &-primary {
        background-color: $accent;
        box-shadow: 0 0 10px rgba(0, 149, 222, 0.6);
        background-image: none;
        &:hover {
            background-color: #23b7ff;
            box-shadow: 0 0 15px rgba(0, 149, 222, 0.6); }
        &:focus {
            box-shadow: none;
            background: $accent;
            outline: none; }
        &:active {
            box-shadow: none;
            background: $accent;
            outline: none; } } }

.btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover, .open>.dropdown-toggle.btn-primary.focus, .open>.dropdown-toggle.btn-primary:focus, .open>.dropdown-toggle.btn-primary:hover {
    border: none;
    background: $accent;
    outline: none;
    box-shadow: 0 0 10px rgba(0, 149, 222, 0.6); }

.ct-block {
    display: block;
    margin-top: 40px;
    &__item {
        position: relative;
        padding: 30px;
        height: 340px;
        margin-bottom: 30px; }
    &__content {
        width: 270px;
        color: #ffffff;
        &_black {
            color: $black; }
        &_w200 {
            width: 200px; } }
    &__image {
        position: absolute;
        bottom: 0; }
    &__heading {
        font-size: 25px;
        letter-spacing: -0.25px;
        font-family: $bold;
        line-height: 28px;
        margin-bottom: 15px; }
    &__text {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 25px; } }

.disclaimer {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 14px;
    color: #ffffff;
    &_black {
        color: #000000; } }

.ct-block-1 {
    background: url('../img/ct-1.png') no-repeat center / cover;
    .ct-block__image {
        right: 20%; } }
.ct-block-2 {
    background: url('../img/ct-2.png') no-repeat center / cover;
    .ct-block__image {
        right: 3%; } }
.ct-block-3 {
    background: url('../img/ct-3.png') no-repeat center / cover;
    .ct-block__image {
        right: 12%; } }
.ct-block-4 {
    background: url('../img/ct-4.png') no-repeat center / cover;
    .ct-block__image {
        right: -15px; } }
.ct-block-5 {
    background: url('../img/ct-5.png') no-repeat center / cover;
    .ct-block__image {
        right: 0; } }
.ct-block-6 {
    background: url('../img/ct-6.png') no-repeat center / cover;
    .ct-block__image {
        right: 0; } }
.ct-block-7 {
    background: url('../img/ct-7.png') no-repeat center / cover;
    .ct-block__image {
        right: 0; } }

.category-banner {
    margin-top: 30px; }

.text {
    font-size: 16px;
    line-height: 24px;
    color: $black; }

.about {
    overflow-x: hidden;
    margin-top: 80px;
    &__heading {
        margin-bottom: 25px; }
    &__text {
        margin-bottom: 40px; } }

.button {
    display: block;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    font-size: 13px;
    font-family: $bold;
    box-shadow: 0 0 15px rgba(9, 156, 227, 0.6);
    border-radius: 40px;
    width: 270px;
    background-color: $accent;
    padding: 24px 0;
    transition: 0.1s;
    border: none;
    &:hover {
        color: #ffffff;
        background-color: #23b7ff;
        box-shadow: 0 0 15px rgba(0, 149, 222, 0.6); }
    &:focus {
        color: #ffffff;
        outline: none; }
    &_default {
        background: transparent;
        border: 1px solid $accent;
        box-shadow: none;
        width: 200px;
        padding: 15px 0; }
    &_one {
        background: none;
        border: 1px solid $accent;
        color: $black;
        margin-left: 15px;
        &:hover {
            background: $accent;
            color: #ffffff; }
        &:focus {
            background-color: transparent;
            color: $black;
            outline: none; } } }

.footer {
    margin-top: 60px;
    background-image: radial-gradient(940px 940px at 50% 100%, #213b87 0%, #1c2951 100%);
    padding: 50px 0;
    &__button {
        margin-left: auto; } }

.footer-nav {
    &__list {
        list-style: none;
        display: flex;
        padding: 0;
        margin: 0;
        justify-content: space-between; }
    &__link {
        font-size: 16px;
        font-family: $bold;
        letter-spacing: 0.32px;
        color: #ffffff;
        transition: 0.1s;
        &:hover {
            color: $accent; }
        &:focus {
            color: #ffffff; } } }

.footer-account {
    text-align: right;
    &__link {
        font-size: 16px;
        color: #ffffff;
        letter-spacing: 0.32px;
        transition: 0.1s;
        &:hover {
            color: $accent; }
        &:focus {
            color: #ffffff; } } }

.row-line {
    border-bottom: 1px solid #415595;
    padding-bottom: 35px; }

.row-line-2 {
    border-bottom: 1px solid #415595;
    padding: 35px 0;
    display: flex;
    align-items: center;
    & .phone-block:first-child {
        margin: 0;
        margin-right: 60px; } }

.footer-phone-block {
    display: inline-block;
    margin: 0;
    a {
        color: #ffffff;
        &:hover {
            color: #ffffff; } }
    span {
        color: #c4c4c4; } }

.email {
    &__text {
        color: #c1c1c1; }
    a {
        color: #ffffff;
        &:hover {
            color: #ffffff; } } }

.footer-info {
    padding-top: 35px;
    display: flex;
    justify-content: space-between;
    color: #c4c4c4;
    &__list {
        display: flex;
        padding: 0;
        margin: 0;
        list-style: none; }
    &__link {
        color: #c4c4c4;
        margin-left: 20px;
        &:hover {
            color: #ffffff; } } }

.remodal-form {
    input, textarea {
        border: none;
        box-shadow: none;
        border-bottom: 1px solid #4b6bab;
        font-size: 16px;
        color: #fff;
        letter-spacing: -0.16px;
        padding: 30px;
        margin-bottom: 10px;
        background: transparent;
        &::placeholder {
            color: #ffffff; }
        &:focus {
            box-shadow: none;
            border-bottom: 2px solid $accent; } }
    &_black {
        input {
            color: $black;
            &::placeholder {
                color: #272727 !important; } } }

    &__btn {
        margin: auto;
        margin-top: 40px; }
    & .error {
        color: #e75f2a;
        text-align: left;
        font-size: 15px; } }

.remodal {
    max-width: 900px;
    background: transparent;
    vertical-align: middle;
    text-align: left; }

.remodal-menu {
    vertical-align: top;
    padding: 0;
    padding: 0 30px; }

.remodal-close {
    color: #ffffff;
    &:hover {
        color: #ffffff; } }

.burger {
    display: block;
    font-size: 30px;
    padding: 10px 0 10px 10px;
    cursor: pointer;
    color: $black;
    &:hover, &:focus {
        color: $black; } }

.mobile-menu {
    &__heading {
        text-align: left;
        font-size: 30px;
        font-family: $bold;
        letter-spacing: -0.3px;
        color: #ffffff; } }

.mob-cat {
    display: block;
    color: #ffffff;
    padding: 0;
    margin: 0;
    list-style: none;
    &__heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
         background-color: #12316e;
        a {
            font-size: 15px;
            color: #ffffff;
            padding: 15px 0 15px 25px;
            &:hover, &:focus {
                color: #ffffff; } } }
    &__arrow {
        cursor: pointer;
        font-size: 20px;
        width: 15%;
        padding-right: 15px;
        text-align: right; } }

.mob-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    &__link {
        display: block;
        color: #ffffff;
        font-size: 15px;
        display: block;
        color: #fff;
        padding: 15px 0 15px 25px;
        border-bottom: 1px solid #6987c3;
        &:hover {
            color: #ffffff; } } }


.mob-cat-dropdown {
    display: none;
    color: #ffffff;
    list-style: none;
    &__link {
        display: block;
        font-size: 15px;
        color: #d8d8d8;
        margin-bottom: 5px;
        padding: 8px 0;
        margin: 0;
        &:hover {
            color: #d8d8d8; } } }

.breadcrumb {
    background-color: none;
    margin: 0;
    padding: 25px 0;
    a {
        font-size: 13px;
        color: #515151;
        &:hover, &:focus {
            color: #515151; } }
    li:first-child a {
        color: $accent; } }

#content {
    h1 {
        margin: 0;
        padding: 0;
        font-size: 40px;
        color: $black;
        letter-spacing: -0.4px;
        font-family: $bold;
        margin-bottom: 15px; }
    p {
        color: $black;
        font-size: 16px;
        line-height: 26px; }
    ul {
        font-size: 16px; }

    b {
        font-family: $bold; }


    .text-about {
        color: #1c1c1c;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 40px; }

    .product-cat {
        text-align: center;
        a {
            margin: 0; } }

    .thumbnail {
        background: none;
        border: none;
        box-shadow: none;
        margin: 0;
        padding: 0; }
    .price-t {
        color: $black;
        font-size: 30px;
        font-family: $bold;
        padding: 15px 0 35px 0; } }

.product-delivery {
    padding: 0;
    list-style: none;
    margin-top: 30px;
    li {
        font-size: 13px;
        margin-bottom: 10px;
        color: #505050;
        i {
            margin-right: 10px;
            font-size: 10px;
            color: $accent; } } }

.amount {
    display: flex;
    position: relative;
    margin-bottom: 40px;
    &__label {
        position: absolute;
        top: -25px;
        left: 0;
        font-size: 13px;
        color: $black;
        font-weight: 400; }
    &__ctrl {
        cursor: pointer;
        width: 45px;
        height: 45px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        font-size: 20px;
        color: #242424;
        text-align: center;
        line-height: 2.2;
        z-index: 1;
        transition: 0.5s;
        &:hover {
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16); } }
    &__input {
        width: 45px;
        height: 45px;
        background-color: $accent;
        color: #ffffff;
        font-size: 15px;
        border: none;
        text-align: center; } }

.category {
    &__item {
        display: block;
        padding: 10px 10px 10px 20px;
        color: $black;
        font-family: $bold;
        font-size: 14px;
        letter-spacing: 0.36px;
        transition: 0.1s;
        &:hover {
            background-color: $accent;
            color: #ffffff; }
        &_child {
            color: #636363;
            font-size: 14px;
            padding-left: 40px;
            font-family: $regular; } }

    & .active {
        background-color: $accent;
        color: #ffffff;
        border-bottom: none; } }

.category-block {
    border: 1px solid #e8e8e8; }

.category-bar {
    display: none;
    position: absolute;
    top: 16px;
    font-size: 15px;
    color: #ffffff;
    right: 15px; }

.category-heading {
    position: relative;
    color: $black;
    margin: 0;
    padding: 0;
    letter-spacing: 2.7px;
    font-size: 18px;
    padding: 15px;
    background-color: $accent;
    color: #ffffff;
    font-family: $bold;
    text-transform: uppercase; }

.category-options {
    margin-bottom: 15px; }

.product-card-heading {
    margin: 0 !important;
    font-size: 25px !important; }

.product-options {
    padding: 0;
    margin: 0;
    list-style: none;
    color: #4a4a4a; }

.description {
    margin-top: 15px;
    .nav li {
        font-size: 16px;
        font-family: $bold;
        color: $black;
        background: #faf8f6;
        a {
            color: $black; } }

    .tab-content {
        font-size: 16px;
        line-height: 26px;
        color: $black;
        padding-top: 13px; } }

.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
    background: #f5f2f0; }

.nav-tabs>li>a:hover {
    border-color: #faf8f6; }

.nav-tabs>li>a {
    border: inherit;
    margin: 0; }

#review {
    .fa-stack {
        font-size: 6px;
        color: $accent; }

    .table-striped>tbody>tr:nth-of-type(odd) {
        background-color: #f4f2f0; }

    .table {
        margin-top: 10px; } }

.dis-card {
    &__info {
        color: #9d9d9d;
        font-size: 13px; }
    &__checkbox {
        margin-top: 10px;
        label {
            display: inline;
            margin: 0; }
        input {
            display: inline;
            width: auto;
            margin: 0;
            margin-right: 15px;
            vertical-align: middle; }
        a {
            display: inline;
            margin-left: 5px;
            color: $accent; } } }

.alert-success {
    background: #24c77c;
    position: fixed;
    color: #ffffff;
    width: 300px;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    top: 30%;
    left: 50%;
    margin-left: -150px;
    padding: 40px;
    border: none;
    z-index: 100;
    a {
        color: #ffffff; }
    button {
        display: none; } }

.simplecheckout {
    .quantity {
        .btn {
            padding: 0;
            width: 30px;
            height: 34px;
            i {
                margin-top: 4px; } }
        input {
            text-align: center;
            border: 1px solid #9e9e9e; } }
    .checkout-heading {
        background-color: $accent;
        color: #ffffff;
        border: none;
        a {
            color: #ffffff;
            font-family: $bold;
            &:hover {
                color: #ffffff; } } } }

.simplecheckout-button-block, .simpleregister-button-block {
    background: #faf8f6; }

.form-control {
    border: none;
    box-shadow: none;
    background: transparent;
    border-bottom: 1px solid #4b6bab;
    border-radius: 0;
    transition: 0.1s;
    &:focus {
        box-shadow: none;
        border-bottom: 2px solid $accent; } }

.has-error .form-control:focus {
    box-shadow: none;
    border-bottom: 2px solid red;
    border-color: red;
    background: transparent; }

.has-error .form-control {
    box-shadow: none;
    border-bottom: 1px solid red;
    border-color: red;
    background: transparent; }

.simplecheckout-block-content textarea {
    background: transparent; }

.simplecheckout-cart th {
    background-color: $accent;
    color: #ffffff !important; }

.account-login, .account-simpleregister {
    .form-group input {
        border: none;
        box-shadow: none;
        background: transparent;
        border-bottom: 1px solid #4b6bab;
        border-radius: 0;
        transition: 0.1s;
        &:focus {
            box-shadow: none;
            border-bottom: 2px solid $accent; } } }

.pagination>li>a, .pagination>li>span {
    background-color: #faf8f6;
    color: $accent; }

.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    background: $accent;
    border-color: $accent; }

.category-options .form-control {
    border: none; }

.description {
    b {
        font-family: $bold; } }

.btn-default.active, .btn-default:active {
    background: transparent;
    border-color: $accent; }

.btn-default.active.focus, .btn-default.active:focus, .btn-default.active:hover, .btn-default:active.focus, .btn-default:active:focus, .btn-default:active:hover, .open>.dropdown-toggle.btn-default.focus, .open>.dropdown-toggle.btn-default:focus, .open>.dropdown-toggle.btn-default:hover {
    color: $black;
    background: transparent;
    outline: none;
    border-color: $accent; }

.simplecheckout-cart a {
    color: $black;
    font-family: $bold; }

.input-group-btn>.btn:active, .input-group-btn>.btn:focus, .input-group-btn>.btn:hover {
    outline: none; }

.product-search {
    .form-control {
        width: 50%;
        margin-bottom: 10px; }
    .checkbox-inline {
        font-size: 15px;
        margin-top: 5px; }
    h2 {
        font-size: 20px;
        margin: 0;
        margin: 25px 0 15px 0; } }

.account-login {
    .login-link {
        margin-left: 15px;
        font-family: $bold; } }

.information-sitemap {
    #content a {
        color: $black; } }

.checkout-simplecheckout .alert-success {
    position: relative;
    font-size: 16px;
    width: auto;
    padding: 12px;
    margin: 0; }

.cat-search {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    li {
        margin-right: 35px;
        text-align: center;
        width: 150px;
        margin-bottom: 30px; }
    a {
        display: block;
        color: #000000; }
    img {
        display: block;
        margin: auto;
        max-width: 100%;
        height: auto; } }

@import "parts/media.sass";
